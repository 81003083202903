import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence, m } from 'framer-motion';

import { breakpoints, screen } from '@/components/common/breakpoints';
import { Icon } from '@/components/common/Icon';
import { Image } from '@/components/common/image';
import { Surface } from '@/components/common/Surface';
import { PortableText } from '@/components/common/utils';
import { useMatchMedia } from '@/lib/useMatchMedia';
import { colourTokens } from '@/styles/colours';

import type { BlockWithAudience, HotspotImage } from '@/types/shared';
import type { Dispatch, SetStateAction } from 'react';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

interface AccordionProps extends BlockWithAudience {
  accordionItems: Array<SanityKeyed<object>>;
  /*
  - singleItem -> allows one item to be opened at the time
  - allItems -> allows all items to be opened
  */
  accordionBehaviour: 'singleItem' | 'allItems';
  openItemsState?: [number[], Dispatch<SetStateAction<number[]>>];
  timer?: number;
  isInView?: boolean;
  oldStyleChevron?: boolean;
  accordionItemsPaddings?: number[];
  primaryImage?: HotspotImage & { alternateText?: string };
}

const Accordion = ({
  accordionItems,
  accordionBehaviour = 'singleItem',
  openItemsState,
  pageAudience,
  isAudienceSwitcherEnabled,
  timer,
  isInView,
  oldStyleChevron = false,
  accordionItemsPaddings,
  primaryImage,
}: AccordionProps) => {
  const [stopAnimation, setStopAnimation] = useState(false);
  const [pauseAnimation, setPauseAnimation] = useState(false);

  return (
    <StyledAccordionList
      onClick={() => setStopAnimation(true)}
      onMouseEnter={() => setPauseAnimation(true)}
      onMouseLeave={() => setPauseAnimation(false)}
    >
      {accordionItems?.map((item, idx) => (
        <AccordionItem
          accordionBehaviour={accordionBehaviour}
          key={item._key}
          itemOrder={idx}
          itemsAmount={accordionItems.length}
          singleItemBehaviourConfig={{ idx, openItemsState }}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
          timer={!stopAnimation && timer}
          pauseAnimation={pauseAnimation}
          isInView={isInView}
          oldStyleChevron={oldStyleChevron}
          accordionItemPadding={accordionItemsPaddings?.[idx]}
          primaryImage={primaryImage}
          {...item}
        />
      ))}
    </StyledAccordionList>
  );
};

const singleItemBehaviour = (
  singleItemBehaviourConfig: {
    idx: number;
    openItemsState: [number[], Dispatch<SetStateAction<number[]>>];
  },
  memo: boolean,
) => {
  const idx = singleItemBehaviourConfig.idx;
  const [openItems, setOpenItems] = singleItemBehaviourConfig.openItemsState;

  return {
    ariaExpanded: openItems.includes(idx),
    isOpen: memo,
    onClick: () => setOpenItems([idx]),
  };
};

const allItemsBehaviour = (
  allItemsState: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  const [isOpen, setIsOpen] = allItemsState;
  return {
    ariaExpanded: isOpen,
    onClick: () => setIsOpen((prev) => !prev),
    isOpen: isOpen,
  };
};

interface AccordionItemProps extends BlockWithAudience {
  _key: string;
  itemOrder: number;
  itemsAmount: number;
  heading?: string;
  body?: Array<SanityKeyed<SanityBlock>>;
  accordionBehaviour: 'singleItem' | 'allItems';
  /* Expected configuration to receive with 'singleItem' accordionBehaviour.
  - idx -> index of current accordionItem
  - openItemsState -> useState to control 1 open item at the time.
  */
  singleItemBehaviourConfig?: {
    idx: number;
    openItemsState: [number[], Dispatch<SetStateAction<number[]>>];
  };
  trackingTag?: { blockName: string; blockAction: string };
  timer?: number;
  isInView?: boolean;
  oldStyleChevron?: boolean;
  accordionItemPadding?: number;
  pauseAnimation?: boolean;
  itemImage?: HotspotImage & { alternateText?: string };
  primaryImage?: HotspotImage & { alternateText?: string };
}

const AccordionItem = ({
  _key,
  itemOrder,
  itemsAmount,
  heading,
  body,
  accordionBehaviour,
  singleItemBehaviourConfig,
  trackingTag,
  pageAudience,
  isAudienceSwitcherEnabled,
  timer,
  isInView,
  oldStyleChevron,
  itemImage,
  primaryImage,
  accordionItemPadding,
  pauseAnimation,
}: AccordionItemProps) => {
  // Configuration for the single item behaviour
  const isMobile = useMatchMedia(breakpoints.md);

  const idx = singleItemBehaviourConfig.idx;
  const openItems = singleItemBehaviourConfig.openItemsState?.[0];
  const memo = useMemo(() => openItems?.includes(idx), [idx, openItems]);

  // Configuration for the all items behaviour
  const [allItemsState, setAllItemsState] = useState(false);

  const handleToggle = () => {
    switch (accordionBehaviour) {
      case 'singleItem': {
        return singleItemBehaviour(singleItemBehaviourConfig, memo);
      }
      case 'allItems':
        return allItemsBehaviour([allItemsState, setAllItemsState]);
    }
  };
  const { ariaExpanded, onClick, isOpen } = handleToggle();

  const getChevronColour = () => {
    if (isOpen) {
      if (oldStyleChevron) {
        return colourTokens.icon.action.token; // OLD style chevron in OPEN state
      } else {
        return colourTokens.icon.inverseStrong.token; // NEW style chevron in OPEN state
      }
    } else if (oldStyleChevron) {
      return colourTokens.icon.inverseStrong.token; // OLD style chevron in CLOSED state
    } else {
      return colourTokens.icon.warmBase.token; // NEW style chevron in CLOSED state
    }
  };

  return (
    <ContentWrapper>
      <ItemWrapper>
        <ItemButton
          type="button"
          aria-label={heading}
          aria-expanded={ariaExpanded}
          aria-controls={`item${_key}`}
          id={`accordion${_key}id`}
          className={
            trackingTag?.blockName &&
            `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
          }
          onClick={onClick}
        >
          <TitleWrapper isOpen={isOpen}>{heading}</TitleWrapper>

          <AnimationWrapper>
            {!!timer && isInView && (
              <TimerAnimation
                timer={timer}
                itemOrder={itemOrder}
                itemsAmount={itemsAmount}
                onAnimationStart={onClick}
                onAnimationIteration={onClick}
                pauseAnimation={pauseAnimation}
              >
                <Icon icon="Timer" size="40" />
              </TimerAnimation>
            )}
            <ChevronWrapper oldStyleChevron={oldStyleChevron} isOpen={isOpen}>
              <Icon icon="ChevronDown" colour={getChevronColour()} />
            </ChevronWrapper>
          </AnimationWrapper>
        </ItemButton>
      </ItemWrapper>
      <AnimatePresence initial={false}>
        {isOpen && (
          <BodyWrapper
            id={`item${_key}`}
            aria-labelledby={`accordion${_key}id`}
            key="content"
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            exit="closed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              closed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.5,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
            accordionItemPadding={accordionItemPadding}
          >
            <PortableText
              value={body}
              audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
            />
            {isMobile && (
              <ImageWrapper>
                <Surface
                  surfaceColour="--surface-warm-base"
                  borderRadius="--radius-component-md"
                  padding={false}
                >
                  <Image
                    {...(primaryImage?.asset ? primaryImage : itemImage)}
                    alt={
                      primaryImage?.alternateText ?? itemImage?.alternateText
                    }
                    objectFit="cover"
                  />
                </Surface>
              </ImageWrapper>
            )}
          </BodyWrapper>
        )}
      </AnimatePresence>
    </ContentWrapper>
  );
};

const ItemButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  text-align: left;
  color: inherit;
  cursor: pointer;
  font: inherit;
`;

const BodyWrapper = styled(m.div)<{ accordionItemPadding: number }>`
  overflow: hidden;
  p {
    ${({ accordionItemPadding }) =>
      !!accordionItemPadding && `padding-bottom: ${accordionItemPadding}px;`}
    color: var(--text-warm-base);
    padding-top: var(--space-component-md);
  }
`;

const TitleWrapper = styled.span<{ isOpen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  color: ${({ isOpen }) =>
    isOpen ? 'var(--text-strong)' : 'var(--text-warm-base)'};

  &:hover {
    color: var(--text-strong);
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-color: var(--border-strong);
  }

  &:focus {
    text-decoration: none;
    border-radius: var(--radius-component-sm);
    border: 2px solid var(--border-action, #4a5ff7);
    color: var(--text-strong);
  }
`;

const ImageWrapper = styled.div`
  ${screen.md} {
    padding: var(--space-component-xxl) 0 var(--space-component-sm);
  }
  padding-top: var(--space-component-xl);

  & img {
    height: 240px;
    border-radius: var(--radius-component-lg);
  }
  ${screen.md} {
    display: block;
  }
`;

const AnimationWrapper = styled.div`
  position: relative;
`;

const TimerAnimation = styled.div<{
  timer: number;
  itemOrder: number;
  itemsAmount: number;
  pauseAnimation: boolean;
}>`
  position: absolute;
  top: -6px;
  left: -6px;
  transform: scaleY(-1);

  svg {
    @keyframes dash {
      0% {
        stroke-dashoffset: ${Math.PI * 36};
      }
      ${({ itemsAmount }) => `${100 / itemsAmount}% 
      {
        stroke-dashoffset: 0;
      }`}

      100% {
        stroke-dashoffset: ${Math.PI * 36};
      }
    }
    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      5%,
      ${({ itemsAmount }) =>
          `${100 / itemsAmount}% {
       opacity: 1;
       } 
       ${100 / itemsAmount + 1}% {
       opacity: 0;
       }`}
        100% {
        opacity: 0;
      }
    }
    opacity: 0;
    // First value - length, Second - delay
    animation: fadeInOut
      ${({ timer, itemsAmount, itemOrder }) =>
        `${timer * itemsAmount}s ${itemOrder * timer}s`}
      linear infinite;

    animation-play-state: ${({ pauseAnimation }) =>
      pauseAnimation ? 'paused' : 'running'};

    .blueCircle {
      stroke-dasharray: ${Math.PI * 36};
      stroke-dashoffset: ${Math.PI * 36};
      animation: dash
        ${({ timer, itemsAmount, itemOrder }) =>
          `${timer * itemsAmount}s ${itemOrder * timer}s`}
        linear infinite;
      animation-play-state: ${({ pauseAnimation }) =>
        pauseAnimation ? 'paused' : 'running'};
    }
  }
`;

const oldChevronStyle = (isOpen: boolean) => {
  return `
    border: 1.5px solid var(--icon-action);
    padding: var(--spacing-150); 
    background-color: ${isOpen ? 'transparent' : 'var(--icon-action)'};
  `;
};

const newChevronStyle = (isOpen: boolean) => {
  return `
    padding: 0.375em;
    border: 0px solid var(--icon-action); 
    background-color: ${
      isOpen ? 'var(--surface-inverse-base)' : 'var(--surface-warm-strong)'
    };
  `;
};

const ChevronWrapper = styled.div<{
  isOpen: boolean;
  oldStyleChevron: boolean;
}>`
  border-radius: 50%;

  ${({ oldStyleChevron, isOpen }) =>
    oldStyleChevron ? oldChevronStyle(isOpen) : newChevronStyle(isOpen)}

  transition: background-color 0.6s;

  svg {
    transition: transform 0.6s;
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  }
`;

const ItemWrapper = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid var(--border-warm-subtle);
  }
`;

const StyledAccordionList = styled.ul`
  li {
    color: var(--text-strong);
  }
`;

export { Accordion };
